<template>
  <a-button
    class="add-table pull-right ml-3"
    type="primary"
    @click="openModal"
  >
    Добавить стол
  </a-button>
  <a-modal
    class="table-create-modal"
    title="Добавить стол"
    :visible="modalVisible"
    :closable="true"
    :confirm-loading="!(!loading && !confirmLoading)"
    @ok="create"
    @cancel="resetForm"
  >
    <a-form :model="table">
      <div class="col-md-12">
        <a-form-item label="Зал">
          <a-select
            v-model:value="table.hall_id"
            :options="halls"
            placeholder="Выберите зал"
          />
        </a-form-item>
        <a-form-item label="Название стола">
          <a-input
            ref="tableName"
            placeholder="Введите название стола"
            v-model:value="table.name"
          />
        </a-form-item>
        <div class="row">
          <div class="col-md-2">
            <a-form-item label="Статус">
              <a-switch
                checked-children="Вкл"
                un-checked-children="Выкл"
                v-model:checked="table.status" />
            </a-form-item>
          </div>
        </div>
      </div>
    </a-form>
  </a-modal>
</template>

<script setup>
import {ref, reactive, toRaw, nextTick} from 'vue';
import apiClient from "@/services/axios";
import { useRoute } from "vue-router";
import Table from "@/models/Table";

const props = defineProps({
  halls: {
    type: Array,
    default: () => {},
  },
})

const emit = defineEmits(['reload']);

let imageUrl = ref(''),
  loading = ref(false),
  confirmLoading = ref(false),
  modalVisible = ref(false),
  tableName = ref("tableName"),
  initialTable = new Table({}),
  table = reactive(initialTable);

const
  route = useRoute(),
  openModal = () => {
    modalVisible.value = true;
    nextTick(() => tableName.value?.focus());
  },
  resetForm = () => {
    Object.assign(table, new Table({}));
    modalVisible.value = false;
  },
  create = () => {
    confirmLoading.value = true;

    let model = toRaw(table);
    model.status = model.status ? 1 : 0
    model.store_id = route.params.store

    return apiClient.post('/desk/create', model).then(function (response) {
      if (response) {
        resetForm()
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      modalVisible.value = false;
      confirmLoading.value = false;
      emit('reload')
    })
  }
</script>

<style scoped>
</style>
